.page-footer {
  padding: 3rem 0;
  font-family: "Poppins", sans-serif;

  .footer-site-logo {
    text-align: center;
    font-size: calc(1rem + 0.5vw);
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.75rem;
    margin-top: 0;
  }
  .nav-links {
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			a {
				padding: 10px;
				color: #000;
				&:hover {
					color: #45a1d1;
				}
			}
		}
  }

  .social {
    h3 {
      font-weight: bold;
      font-size: 18px;
      color: #000;
    }
    ul li {
      display: inline-block;
    }
    ul li a {
      display: inline-block;
      padding: 10px;
    }
    .in {
      color: #df588a;
    }
    .fb {
      color: #15b8ff;
    }
    .tw {
      color: #3742d0;
    }
  }
  
  .copyright {
    color: #999999;
  }
}
