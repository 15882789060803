.navbar {
  background-color: #F8EDED;

  #navbar-container {
    border-bottom: 3px solid #000;
  }

  .nav-link {
    color: #000;
    font-family: Montserrat,sans-serif !important;
    font-weight: 700;
    z-index: 1002;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: #FF8225;
    }

    &.active {
      transition: all .8s ease;
      background-color: #FF8225;
    }
  }

  .logo {
    width: 270px;
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}