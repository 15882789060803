@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

$font-family-sans-serif-2: "Poppins", sans-serif;
$font-family-serif: "Source Serif Pro", serif;

body {
  font-family: $font-family-sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Montserrat,sans-serif;
}
a {
  transition: 0.3s all ease;
  &,
  &:hover {
    text-decoration: none !important;
  }
}

.content {
  height: 70vh;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 30px !important;
}

.fs-22 {
  font-size: 1.38rem !important;
  line-height: 30px !important;
}

.fs-20 {
  font-size: 1.15rem !important;
  line-height: 30px !important;
}

.fs-18 {
	font-size: 1.08rem !important;
	line-height: 22px !important;
  }

.fs-16 {
  font-size: 1rem !important;
  line-height: 19px !important;
}

h1 {
  font-weight: 800  !important;
  line-height: 1.2  !important;
  margin-bottom: .75rem  !important;
  margin-top: 0  !important;
  font-size: 3.5rem  !important;
}

h2 {
  font-size: 2.25rem  !important;
  font-weight: 800  !important;
  line-height: 1.2  !important;
  margin-bottom: .75rem  !important;
  margin-top: 0  !important;
}

h3 {
  font-weight: 700 !important;
  line-height: 1.2  !important;
}
