#mainBanner {
  h1 {
    font-size: calc(1.475rem + 2vw);
    font-family: Montserrat,sans-serif;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.75rem;
    margin-top: 0;
  }

  .banner-text {
    padding-bottom: 5rem;
  }

  .scale-1 {
    transition: all .3s;
  }

  .img {
    width: 100%;
    height: 100%;
  }

  #banner-actions {
    border-radius: 23px;
    background: #B43F3F;
    box-shadow:  0px 0px 1px #b43f3fa9,
                 2px 2px 1px #b43f3fa9;
  }

  .custom-primary-button {
    background-color: #FF8225;
  }
}