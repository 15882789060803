.contactContainer {
  .copy-2 {
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }

  .link {
    font-size: 1.5rem !important;
    line-height: 30px !important;
    color: #067c84;
    text-decoration: none;
  }

  .contact-image {
    max-height: 350px;
    display: block;
    max-width: 100%;
    width: 350px;
  }
}